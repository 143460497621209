import { useState, createContext, useMemo } from 'react';
import ThemePreferences from '../Themes/ThemePreferences';
import { LocalStorage } from '../Utils/LocalStorage';

export const PreferenceContext = createContext();

export function PreferenceProvider({ children }) {
  // Set theme preference state based on localStorage or system preference
  const [themePreference, setThemePreference] = useState(
    localStorage.getItem(LocalStorage.theme)
    || (window.matchMedia('(prefers-color-scheme: dark)').matches
      ? ThemePreferences.dark : ThemePreferences.light)
  );

  const providerValue = useMemo(() => ({
    themePreference, setThemePreference
  }), [themePreference]);

  // return context provider
  return (
    <PreferenceContext.Provider value={providerValue}>
      {children}
    </PreferenceContext.Provider>
  );
}
