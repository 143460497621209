import { Box, Stack, Typography, Button } from "@mui/material";
import CollapsibleSection from "./CollapsibleSection";
import Row from "./Row";
import { categorizedLocationTypes, TIME_BLOCK_WIDTH } from "../Utils/GlobalVariables";
import { BLOCKS_IN_A_SECTION, INTERVALS_PER_HOUR } from '../Utils/TimeBlockUtils';
import { CollapsibleSections } from '../Utils/TimeBlockUtils';
import { useTimeBlocks } from "../ContextProviders/TimeBlocksContext";
import { generateEmptyTimeBlocksArray, getHourStringFromDateString } from "../Utils/TimeBlockUtils";
import { useContext } from "react";
import { SurveyMetadataContext } from "../ContextProviders/SurveyMetadataContext";

const LocationSurveyGrid = () => {
    const { timeBlocks, setTimeBlocks, handleRowActivate, isTouching, setIsTouching } = useTimeBlocks();

    const { surveyDate } = useContext(SurveyMetadataContext);

    const handleClearAll = () => {
        setTimeBlocks(generateEmptyTimeBlocksArray(surveyDate)); // Reset all blocks
    };

    const handleTouchStart = () => {
        setIsTouching(true);
    };

    const handleTouchEnd = () => {
        setIsTouching(false);
    };

    const handleTouchMove = (e) => {
        if (!isTouching) return;

        const touch = e.touches[0];
        const element = document.elementFromPoint(touch.clientX, touch.clientY);

        if (element && element.dataset.rowIndex && element.dataset.blockIndex) {
            const rowIndex = parseInt(element.dataset.rowIndex, 10);
            const blockIndex = parseInt(element.dataset.blockIndex, 10);
            handleRowActivate(rowIndex, blockIndex); // Activate the touched block
        }
    };

    return (
        <>
            <Stack gap={2} direction="row" justifyContent="end" sx={{ mb: 1 }}>
                <Button size="small" variant="text" color="primary" >
                    Reset to initial
                </Button>

                <Button size="small" variant="text" color="error" onClick={handleClearAll}>
                    Clear All
                </Button>
            </Stack>

            <Stack
                direction="column"
                sx={{
                    overflowY: "auto", // Enables scrolling
                    height: "100%", // Ensures the container takes up available space
                }}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onTouchMove={handleTouchMove} // Handle touch move globally
            >
                {CollapsibleSections.map((section, sectionIndex) => {
                    const timeBlocksCount = timeBlocks.slice(
                        section.startHour * INTERVALS_PER_HOUR,
                        section.endHour * INTERVALS_PER_HOUR
                    ).filter((timeBlock) => timeBlock.location_type !== null).length;

                    const progressPercentage = Math.round((timeBlocksCount / BLOCKS_IN_A_SECTION) * 100);

                    return (
                        <CollapsibleSection
                            key={sectionIndex}
                            label={section.label}
                            secondaryLabel={`${progressPercentage}% done`}
                            defaultExpanded={sectionIndex === 0} // Expand the first section by default
                        >
                            <Stack spacing={3} direction="row" sx={{ mb: 2 }}
                                justifyContent="center"
                            >
                                <Box sx={{ width: "4rem" }} />
                                <Stack spacing={0} direction="row" alignItems="end">
                                    {categorizedLocationTypes.map((locationType) => (
                                        <Typography
                                            key={locationType.id}
                                            variant="caption"
                                            fontWeight={600}
                                            sx={{
                                                userSelect: "none",
                                                width: TIME_BLOCK_WIDTH + 2 * 8,
                                                wordWrap: "break-word",
                                                lineHeight: 1
                                            }}
                                        >
                                            {locationType.name}
                                        </Typography>
                                    ))}
                                </Stack>
                            </Stack>

                            {Array.from({ length: section.endHour - section.startHour }, (_, i) => section.startHour + i).map((hourIndex) => (
                                <Stack
                                    key={hourIndex}
                                    direction="row"
                                    alignItems="start"
                                    justifyContent="center"
                                    spacing={1}
                                >
                                    {/* Hour label */}
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            userSelect: "none",
                                            width: "4rem", textAlign: "right", marginTop: "-0.5rem !important"
                                        }}
                                    >
                                        {getHourStringFromDateString(timeBlocks[hourIndex * INTERVALS_PER_HOUR]?.start_date)}
                                    </Typography>

                                    {/* Rows for each hour */}
                                    <Stack key={hourIndex} direction="column">
                                        {Array.from({ length: INTERVALS_PER_HOUR }).map((_, minuteIndex) => {
                                            const rowIndex = hourIndex * INTERVALS_PER_HOUR + minuteIndex;
                                            return (
                                                <Row
                                                    key={rowIndex}
                                                    rowIndex={rowIndex}
                                                    selectedBlock={timeBlocks[rowIndex]} // Pass active block index for this row
                                                    onActivate={(blockIndex) =>
                                                        handleRowActivate(rowIndex, blockIndex)
                                                    }
                                                />
                                            );
                                        })}
                                    </Stack>
                                </Stack>
                            ))}
                        </CollapsibleSection>
                    );
                })}
            </Stack>
        </>

    );
};

export default LocationSurveyGrid;
