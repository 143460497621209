import { React, useMemo, useContext, useState } from "react";

import { Box, Button, CircularProgress, Paper, Stack, Typography } from "@mui/material/";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import ThemePreferences from "./Themes/ThemePreferences";
import CustomThemes from "./Themes/CustomThemes";

import { PreferenceContext } from "./ContextProviders/PreferenceContext";
import LocationSurveyGrid from "./Components/LocationSurveyGrid";
import SummarySection from "./Components/SummarySelection";

import SendIcon from '@mui/icons-material/Send';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { useTimeBlocks } from "./ContextProviders/TimeBlocksContext";
import { fetchDataFromURL } from "./API/ApiFetch";
import { API_URL } from "./Utils/GlobalVariables";
import { RESTmethods } from "./API/Utils";
import { SurveyMetadataContext } from "./ContextProviders/SurveyMetadataContext";
import { AlertSeverity, useNotificationContext } from "./ContextProviders/NotificationContext";
import SnackbarNotification from "./Components/SnackbarNotification";

// Create theme design tokens based on theme preference
const getDesignTokens = (themePreference) => ({
  palette: {
    mode: themePreference,
    ...(themePreference === ThemePreferences.dark
      ? {
        ...CustomThemes.dark.palette,
        ...CustomThemes.universal.palette,
        typography: CustomThemes.universal.palette,
      }
      : {
        ...CustomThemes.light.palette,
        ...CustomThemes.universal.palette,
        typography: CustomThemes.universal.palette,
      }),
  },
});

function App() {
  const { themePreference } = useContext(PreferenceContext);

  // Create theme using getDesignTokens
  const theme = useMemo(
    () => createTheme(getDesignTokens(themePreference)),
    [themePreference]
  );

  // set backgroundColor of 'body' element depending on theme.
  // this is to set bg-color of left/right padding on landscape iOS devices
  document.body.style.background = theme.palette.customAlternateBackground;

  const { timeBlocks, allBlocksFilled } = useTimeBlocks();
  const { pseudonym, surveyDate } = useContext(SurveyMetadataContext);

  const { setShowNotification, setMessage, setSeverity } = useNotificationContext();

  const [isSending, setIsSending] = useState(false);
  const [hasSent, setHasSent] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarNotification />
      <Stack
        sx={{
          p: 2,
          height: "100dvh",
          backgroundColor: "customBackground",
        }}
      >
        <Typography variant="body2" color="text.secondary">
          <b>Survey: {surveyDate}</b>
        </Typography>

        <Typography variant="body2" color="text.secondary">
          <b>Touch/Swipe the blocks</b> to select the location types for each time interval.
        </Typography>

        {/* Make the middle section scrollable */}
        <Box
          sx={{
            flexGrow: 1,
            my: 2,
            overflow: "auto", // Enables scrolling for overflow content
          }}
        >
          {!hasSent ? <LocationSurveyGrid /> : null}
        </Box>

        {/* Sticky bottom section */}
        <Stack
          sx={{
            position: "sticky",
            bottom: 0, // Sticks to the bottom of the viewport
            zIndex: 1,
            gap: 1,
            backgroundColor: "inherit", // To blend with the background
          }}
        >
          <Paper sx={{ px: 2, py: 1 }}>
            <SummarySection />
          </Paper>

          <Button
            variant="contained"
            fullWidth
            startIcon={isSending ? null : (allBlocksFilled ? (hasSent ? <CheckCircleOutlineIcon /> : <SendIcon />) : null)}
            disabled={!allBlocksFilled || hasSent}
            size="small"
            sx={{
              height: "2rem"
            }}
            onClick={() => {
              setIsSending(true);

              fetchDataFromURL({
                url: `${API_URL}/locations/${pseudonym}`,
                restMethod: RESTmethods.POST,
                body: timeBlocks
              }).then((data) => {
                console.log(data);
                setShowNotification(true);
                setMessage("Success, closing this window in 3 seconds...");
                setSeverity(AlertSeverity.success);
                setHasSent(true);

                setTimeout(() => {
                  window.webkit.messageHandlers.didCompleteSurvey.postMessage("SurveyCompleted");
                }, 3000);

              }).catch((error) => {
                console.log(error);
                setShowNotification(true);
                setMessage(error.message || "An error occured, please try again.");
                setSeverity(AlertSeverity.error);
              }).finally(() => {
                setIsSending(false)
              })
            }}
          >
            {isSending ? <CircularProgress sx={{ color: "white" }} size="1.5rem" /> :
              (allBlocksFilled ? (hasSent ? "SURVEY SENT" : "SEND") : "COMPLETE THE SURVEY TO SEND")
            }
          </Button>
        </Stack>
      </Stack>

    </ThemeProvider>
  );
}

export default App;
