import { useMemo } from "react";
import { Box, Stack, Grid, Typography } from "@mui/material";
import { useTimeBlocks } from "../ContextProviders/TimeBlocksContext";
import { allLocationTypes, LocationTypes } from "../Utils/GlobalVariables";
import { TIME_BLOCK_INTERVAL_MINUTES } from "../Utils/TimeBlockUtils";

const SummarySection = () => {
    const { timeBlocks } = useTimeBlocks();

    // Calculate totals for each category
    const summaryTotals = useMemo(() => {
        const totals = {
            [LocationTypes.home.id]: 0,
            [LocationTypes.indoors.id]: 0,
            [LocationTypes.outdoors.id]: 0,
            [LocationTypes.uncategorized.id]: 0,
        };

        timeBlocks.forEach((timeBlock) => {
            const locationType = timeBlock.location_type || LocationTypes.uncategorized.id;
            totals[locationType] += TIME_BLOCK_INTERVAL_MINUTES;
        });

        return Object.keys(totals).map((key) => ({
            hours: Math.floor(totals[key] / 60),
            minutes: totals[key] % 60,
        }));
    }, [timeBlocks]);

    return (
        <Box>
            <Typography variant="body2" fontWeight="bold" gutterBottom>
                SUMMARY
            </Typography>

            <Grid container justifyContent="space-between" columnSpacing={1}>
                {allLocationTypes.map((category, index) => (
                    <Grid item xs={6} key={index} >
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="row" gap={0.5} alignItems="baseline">
                                <Box
                                    sx={{
                                        backgroundColor: category.color,
                                        height: "0.5rem",
                                        width: "0.5rem",
                                    }}
                                />
                                <Typography key={index} variant="caption" color="text.primary">
                                    {category.name}
                                </Typography>
                            </Stack>

                            <Typography key={index} variant="caption" color="text.secondary">
                                {summaryTotals[index].hours}h{" "}
                                {summaryTotals[index].minutes}m
                            </Typography>
                        </Stack>
                    </Grid>
                ))}
            </Grid>


        </Box>
    );
};

export default SummarySection;
