import { useState, useEffect, createContext, useMemo } from 'react';

export const SurveyMetadataContext = createContext();

export function SurveyMetadataProvider({ children }) {
    const [pseudonym, setPseudonym] = useState(null);
    const [surveyDate, setSurveyDate] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const pseudonymParam = params.get('pseudonym');
        const dateParam = params.get('date');

        if (pseudonymParam) {
            setPseudonym(pseudonymParam);
        }
        if (dateParam) {
            setSurveyDate(dateParam);
        }
    }, []);

    const providerValue = useMemo(() => ({
        pseudonym, setPseudonym,
        surveyDate, setSurveyDate
    }), [pseudonym, surveyDate]);

    return (
        <SurveyMetadataContext.Provider value={providerValue}>
            {children}
        </SurveyMetadataContext.Provider>
    );
}
