export const DATE_HOUR_DELIMITER = " ";
export const CollapsibleSections = [
    { label: "00:00 - 07:59", startHour: 0, endHour: 8 },
    { label: "08:00 - 15:59", startHour: 8, endHour: 16 },
    { label: "16:00 - 23:59", startHour: 16, endHour: 24 },
];

export const TIME_BLOCK_INTERVAL_MINUTES = 30;
export const INTERVALS_PER_HOUR = 60 / TIME_BLOCK_INTERVAL_MINUTES;
export const HOURS_IN_A_DAY = 24;
export const BLOCKS_IN_A_SECTION = HOURS_IN_A_DAY / CollapsibleSections.length * INTERVALS_PER_HOUR;

const formatDateToString = (date) => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}${DATE_HOUR_DELIMITER}${hours}:${minutes}${DATE_HOUR_DELIMITER}+04:00`;
};

export const getHourStringFromDateString = (dateString) => {
    if (!dateString) return;
    return dateString.split(DATE_HOUR_DELIMITER)[1];
};

export const generateEmptyTimeBlocksArray = (surveyDate) => {
    const timeBlocks = [];
    for (let hour = 0; hour < HOURS_IN_A_DAY; hour++) {
        for (let interval = 0; interval < INTERVALS_PER_HOUR; interval++) {
            const startMinute = (60 / INTERVALS_PER_HOUR) * interval;
            const endMinute = startMinute + (60 / INTERVALS_PER_HOUR) - 1;
            const startDate = new Date(surveyDate);
            startDate.setUTCHours(hour, startMinute, 0, 0);
            startDate.setTime(startDate.getTime()); // Convert to GMT+4
            const endDate = new Date(surveyDate);
            endDate.setUTCHours(hour, endMinute);
            endDate.setTime(endDate.getTime()); // Convert to GMT+4

            timeBlocks.push({
                i: hour * INTERVALS_PER_HOUR + interval,
                start_date: formatDateToString(startDate),
                end_date: formatDateToString(endDate),
                location_method: "survey",
                location_type: null
            });
        }
    }
    return timeBlocks;
};

