import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { PreferenceProvider } from './ContextProviders/PreferenceContext';
import { TimeBlocksProvider } from './ContextProviders/TimeBlocksContext';
import { SurveyMetadataProvider } from './ContextProviders/SurveyMetadataContext';
import { NotificationProvider } from './ContextProviders/NotificationContext';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <PreferenceProvider>
      <NotificationProvider>
        <SurveyMetadataProvider>
          <TimeBlocksProvider>
            <App />
          </TimeBlocksProvider>
        </SurveyMetadataProvider>
      </NotificationProvider>
    </PreferenceProvider>
  </React.StrictMode>
);