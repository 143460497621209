import { Snackbar, Alert, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material/';
import { AlertSeverity, useNotificationContext } from '../ContextProviders/NotificationContext';

const SnackbarNotification = () => {
  const { showNotification, setShowNotification, message, severity } = useNotificationContext();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;

    setShowNotification(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      onClick={handleClose}
    >
      <Close color="secondary" fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      size="small"
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={showNotification}
      onClose={handleClose}
      autoHideDuration={10000} // 10 seconds
      action={action}
    >
      <Alert
        onClose={handleClose}
        severity={severity === AlertSeverity.primary ? AlertSeverity.info : severity}
        variant="filled"
        color={severity === AlertSeverity.primary ? "primary" : ""}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SnackbarNotification;