export const RESTmethods = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const SupportedFetchExtensions = {
  json: "json",
  csv: "csv",
};
