const CustomThemes = {
  dark: {
    palette: {
      mode: 'dark',
      primary: {
        main: '#a947eb'
      },
      background: {
        paper: '#202020',
        paperBackgroundGradient: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))',
        default: '#303030',
        NYUpurpleLight: 'rgba(169, 71, 235, 0.3)'
      },
      customBackground: '#000000',
      customAlternateBackground: '#202020',
      text: {
        secondaryRGB: '#c1c1c1',
      }
    }
  },
  light: {
    palette: {
      mode: 'light',
      primary: {
        main: '#57068c'
      },
      background: {
        NYUpurpleLight: 'rgba(87, 6, 140, 0.1)'
      },
      customBackground: '#f3f2f7',
      customAlternateBackground: '#ffffff',
      text: {
        secondaryRGB: '#666666',
      }
    }
  },
  universal: {
    palette: {
      NYUpurple: '#57068c',
      backgroundColorForNavLink: 'rgba(0, 0, 0, 0.2)'
    },
    typography: {
      fontFamily: "sans-serif !important"
    }
  }
};


export default CustomThemes;
